import classNames from "classnames";
import { ReactNode } from "react";

export default function DeliveryToggle({
  selected = "yes",
  handleSelect,
}: {
  selected?: "yes" | "no";
  handleSelect: (value: "yes" | "no") => void;
}) {
  return (
    <>
   <div className="flex gap-2 border border-[#4A4A4A] rounded-[8px] md:rounded-xl h-[52px] md:h-[64px] p-2 w-full max-w-[532px]">
       <Toggle selected={selected === "yes"} onClick={() => handleSelect("yes")}>
         {selected === "yes" ? selectedVehicle : unselectedVehicle}
         Yes, Please
       </Toggle>

       <Toggle selected={selected === "no"} onClick={() => handleSelect("no")}>
         {selected === "no" ? selectedVehicle : unselectedVehicle}
         No, Please
       </Toggle>
     </div>
    </>
  );
}

function Toggle({
  children,
  selected = false,
  onClick,
}: {
  children: ReactNode;
  selected?: boolean;
  onClick: () => void;
}) {
  return (
    <button
      className={classNames(
        "flex items-center justify-center gap-1 rounded-[4px] md:rounded-[8px] font-medium text-[12px] md:text-[16px] text-[#4A4A4A] w-full cursor-pointer transition",
        { "bg-[#CFF0FC]": selected }
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

const selectedVehicle = (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_11107_5139"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="33"
      height="32"
    >
      <rect x="0.5" width="32" height="32" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_11107_5139)">
      <path
        d="M8.29388 26.077C7.25299 26.077 6.36932 25.713 5.64288 24.985C4.91643 24.257 4.55321 23.3731 4.55321 22.3333H2.29688V8.41033C2.29688 7.73678 2.53021 7.16667 2.99688 6.7C3.46354 6.23333 4.03354 6 4.70688 6H22.8609V11.077H26.3992L30.7069 16.8463V22.3333H28.2969C28.2969 23.3731 27.9325 24.257 27.2039 24.985C26.4754 25.713 25.5909 26.077 24.5502 26.077C23.5095 26.077 22.6259 25.713 21.8992 24.985C21.1728 24.257 20.8095 23.3731 20.8095 22.3333H12.0402C12.0402 23.376 11.676 24.2606 10.9475 24.987C10.2191 25.7137 9.33454 26.077 8.29388 26.077ZM8.29688 24.077C8.78576 24.077 9.19854 23.9086 9.53521 23.5717C9.87188 23.235 10.0402 22.8222 10.0402 22.3333C10.0402 21.8444 9.87188 21.4316 9.53521 21.0947C9.19854 20.758 8.78576 20.5897 8.29688 20.5897C7.80799 20.5897 7.3951 20.758 7.05821 21.0947C6.72154 21.4316 6.55321 21.8444 6.55321 22.3333C6.55321 22.8222 6.72154 23.235 7.05821 23.5717C7.3951 23.9086 7.80799 24.077 8.29688 24.077ZM24.5532 24.077C25.0421 24.077 25.4549 23.9086 25.7915 23.5717C26.1284 23.235 26.2969 22.8222 26.2969 22.3333C26.2969 21.8444 26.1284 21.4316 25.7915 21.0947C25.4549 20.758 25.0421 20.5897 24.5532 20.5897C24.0643 20.5897 23.6514 20.758 23.3145 21.0947C22.9779 21.4316 22.8095 21.8444 22.8095 22.3333C22.8095 22.8222 22.9779 23.235 23.3145 23.5717C23.6514 23.9086 24.0643 24.077 24.5532 24.077ZM22.8609 17.6667H28.8352L25.3735 13.077H22.8609V17.6667Z"
        fill="#282828"
      />
    </g>
  </svg>
);

const unselectedVehicle = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_11107_5145"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="32"
      height="32"
    >
      <rect width="32" height="32" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_11107_5145)">
      <path
        d="M7.79388 26.077C6.75299 26.077 5.86932 25.713 5.14288 24.985C4.41643 24.257 4.05321 23.3731 4.05321 22.3333H1.79688V8.41033C1.79688 7.73678 2.03021 7.16667 2.49688 6.7C2.96354 6.23333 3.53354 6 4.20688 6H22.3609V11.077H25.8992L30.2069 16.8463V22.3333H27.7969C27.7969 23.3731 27.4325 24.257 26.7039 24.985C25.9754 25.713 25.0909 26.077 24.0502 26.077C23.0095 26.077 22.1259 25.713 21.3992 24.985C20.6728 24.257 20.3095 23.3731 20.3095 22.3333H11.5402C11.5402 23.376 11.176 24.2606 10.4475 24.987C9.7191 25.7137 8.83454 26.077 7.79388 26.077ZM7.79688 24.077C8.28576 24.077 8.69854 23.9086 9.03521 23.5717C9.37188 23.235 9.54021 22.8222 9.54021 22.3333C9.54021 21.8444 9.37188 21.4316 9.03521 21.0947C8.69854 20.758 8.28576 20.5897 7.79688 20.5897C7.30799 20.5897 6.8951 20.758 6.55821 21.0947C6.22154 21.4316 6.05321 21.8444 6.05321 22.3333C6.05321 22.8222 6.22154 23.235 6.55821 23.5717C6.8951 23.9086 7.30799 24.077 7.79688 24.077ZM3.79688 20.3333H4.76088C5.04465 19.8393 5.45443 19.4252 5.99021 19.091C6.52621 18.7568 7.12843 18.5897 7.79688 18.5897C8.44799 18.5897 9.04576 18.7547 9.59021 19.0847C10.1347 19.4147 10.5488 19.8309 10.8325 20.3333H20.3609V8H4.20688C4.10443 8 4.01043 8.04278 3.92488 8.12833C3.83954 8.21367 3.79688 8.30767 3.79688 8.41033V20.3333ZM24.0532 24.077C24.5421 24.077 24.9549 23.9086 25.2915 23.5717C25.6284 23.235 25.7969 22.8222 25.7969 22.3333C25.7969 21.8444 25.6284 21.4316 25.2915 21.0947C24.9549 20.758 24.5421 20.5897 24.0532 20.5897C23.5643 20.5897 23.1514 20.758 22.8145 21.0947C22.4779 21.4316 22.3095 21.8444 22.3095 22.3333C22.3095 22.8222 22.4779 23.235 22.8145 23.5717C23.1514 23.9086 23.5643 24.077 24.0532 24.077ZM22.3609 17.6667H28.3352L24.8735 13.077H22.3609V17.6667Z"
        fill="#4A4A4A"
      />
    </g>
  </svg>
);
