import { Controller, UseFormSetValue } from "react-hook-form";
import Input from "../input";
import Select from "../select";
import { capitalizeFirstLetter } from "../../utils/functions";
import { useEffect, useRef, useState } from "react";
import { Autocomplete, Libraries, LoadScript } from "@react-google-maps/api";
import { Address, I_Order } from "../../pages/create-order/types";
import Pricing from "./pricing";
import classNames from "classnames";
import useRequest from "../hooks/use-request";

declare global {
  interface Window {
    googleMaps: () => void;
    google: any;
  }
}

const libraries: Libraries = ["places"];

export function DeliveryOptions({
  show,
  control,
  setValue,
}: {
  show: boolean;
  control: any;
  setValue: UseFormSetValue<I_Order>;
}) {
  const logisticProviders = ["bolt"];
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY!;

  const { makeRequest: getDeliveryEstimate, loading: deliveryEstimateLoading } =
    useRequest("/orders/logistics/estimation", "POST");

  const [pickupAddressAutoComplete, setPickupAddressAutoComplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const pickupAddressRef = useRef<HTMLInputElement>(null);

  const [deliveryAddressAutoComplete, setDeliveryAddressAutoComplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const deliveryAddressRef = useRef<HTMLInputElement>(null);

  const [deliveryAddress, setDeliveryAddress] = useState<Address | null>(null);
  const [pickupAddress, setPickupAddress] = useState<Address | null>(null);
  const [logisticProvider, setLogisticProvider] = useState(
    logisticProviders[0]
  );
  const [deliveryEstimate, setDeliveryEstimate] = useState(0);

  function pickupAddressOnLoad(instance: google.maps.places.Autocomplete) {
    setPickupAddressAutoComplete(instance);
  }

  function deliveryAddressOnLoad(instance: google.maps.places.Autocomplete) {
    setDeliveryAddressAutoComplete(instance);
  }

  function getAddress(place: google.maps.places.PlaceResult) {
    const name = place.formatted_address!;
    const latitude = place.geometry!.location!.lat();
    const longitude = place.geometry!.location!.lng();

    return { name, latitude, longitude };
  }

  //  Pickup address
  useEffect(() => {
    if (pickupAddressAutoComplete) {
      const listener = pickupAddressAutoComplete.addListener(
        "place_changed",
        () => {
          const place = pickupAddressAutoComplete.getPlace();

          if (place.geometry) {
            const { name, latitude, longitude } = getAddress(place);
            setValue("pickupAddress", { name, latitude, longitude });
            setPickupAddress({ name, latitude, longitude });
          }
        }
      );

      return () => listener.remove();
    }
  }, [pickupAddressAutoComplete]);

  //  Delivery address
  useEffect(() => {
    if (deliveryAddressAutoComplete) {
      const listener = deliveryAddressAutoComplete.addListener(
        "place_changed",
        () => {
          const place = deliveryAddressAutoComplete.getPlace();

          if (place.geometry) {
            const { name, latitude, longitude } = getAddress(place);
            setValue("deliveryAddress", { name, latitude, longitude });
            setDeliveryAddress({ name, latitude, longitude });
          }
        }
      );

      return () => listener.remove();
    }
  }, [deliveryAddressAutoComplete]);

  useEffect(() => {
    if (pickupAddress && deliveryAddress && logisticProvider) {
      getDeliveryEstimate({
        deliveryAddress,
        pickupAddress,
        provider: logisticProvider,
      }).then(
        ([
          {
            data: { price },
          },
        ]) => setDeliveryEstimate(price)
      );
    }
  }, [deliveryAddress, pickupAddress, logisticProvider]);

  return (
    <LoadScript googleMapsApiKey={apiKey} libraries={libraries}>
      <div
        className={classNames({
          hidden: !show,
          "md:grid md:grid-cols-2 gap-10 grid gap-y-6  border-b-[1px] py-7 border-[#4A4A4A]":
            show,
        })}
      >
        <Controller
          name="logisticsProvider"
          control={control}
          rules={{ required: "Select a Platform" }}
          render={({ field, fieldState }) => (
            <Select
              label="Logistics Provider "
              name="logisticsProvider"
              options={logisticProviders.map((provider) => {
                return {
                  value: provider,
                  label: capitalizeFirstLetter(provider),
                };
              })}
              className="w-full"
              onChange={(selectedValue) => {
                field.onChange(selectedValue);
                setLogisticProvider(selectedValue);
              }}
              error={fieldState?.error?.message}
            />
          )}
        />

        <Autocomplete onLoad={pickupAddressOnLoad}>
          <Controller
            name="pickupAddress"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <Input
                value={field.value?.name || undefined}
                ref={pickupAddressRef}
                label="Pick-up Address"
                className="w-full"
                error={fieldState?.error?.message}
                onChange={field.onChange}
              />
            )}
          />
        </Autocomplete>

        <Autocomplete onLoad={deliveryAddressOnLoad}>
          <Controller
            name="deliveryAddress"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <Input
                value={field.value?.name || undefined}
                ref={deliveryAddressRef}
                label="Delivery Address"
                className="w-full"
                error={fieldState?.error?.message}
                onChange={field.onChange}
              />
            )}
          />
        </Autocomplete>

        <Pricing
          loading={deliveryEstimateLoading}
          title="Estimated Delivery Cost"
        >
          {deliveryEstimate && deliveryEstimate}
        </Pricing>
      </div>
    </LoadScript>
  );
}
